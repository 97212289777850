<template>
<v-data-table
    :headers="getUserHeadersByTabName($route.params.tab)"
    :items="users"
    :search="search"
    :mobile-breakpoint="100"
    :options.sync="options"
    :server-items-length="total"
    :loading="loading"
    :footer-props="{
      'items-per-page-options': $store.getters['nav/itemsPerPageOptions'],
      'disable-pagination': loading,
      'disable-items-per-page': loading,
    }"
    class="seller-products-table cursor-pointer"
    @click:row="handleRowClick"
    @update:items-per-page="updateItemsPerPage"
    @update:page="getUsersFromApi"
    @update:sort-desc="getUsersFromApi"
    :items-per-page="globalItemsPerPage"
>
    <template #[`item.roles`]="{item}">
        <span v-if="item.roles.length" v-for="role in item.roles" :key="role">
            <span v-if="role == 'Seller'" class="role-table">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0.0498047V3.0798C14.39 3.5698 17 6.4698 17 9.9998C17 10.8998 16.82 11.7498 16.52 12.5398L19.12 14.0698C19.68 12.8298 20 11.4498 20 9.9998C20 4.8198 16.05 0.549805 11 0.0498047ZM10 16.9998C6.13 16.9998 3 13.8698 3 9.9998C3 6.4698 5.61 3.5698 9 3.0798V0.0498047C3.94 0.549805 0 4.8098 0 9.9998C0 15.5198 4.47 19.9998 9.99 19.9998C13.3 19.9998 16.23 18.3898 18.05 15.9098L15.45 14.3798C14.17 15.9798 12.21 16.9998 10 16.9998Z" fill="#56CA00" />
                </svg>
                {{ role }}
            </span>
            <span v-else-if="role == 'Moderator' || role == 'Admin'" class="role-table">
                <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 14C21.1 14 21.99 13.1 21.99 12L22 2C22 0.9 21.1 0 20 0H4C2.9 0 2 0.9 2 2V12C2 13.1 2.9 14 4 14H0V16H24V14H20ZM4 2H20V12H4V2Z" fill="#FF4C51" />
                </svg>
                {{ role }}
            </span>
            <span v-else class="role-table">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 1.9C9.16 1.9 10.1 2.84 10.1 4C10.1 5.16 9.16 6.1 8 6.1C6.84 6.1 5.9 5.16 5.9 4C5.9 2.84 6.84 1.9 8 1.9ZM8 10.9C10.97 10.9 14.1 12.36 14.1 13V14.1H1.9V13C1.9 12.36 5.03 10.9 8 10.9ZM8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 9C5.33 9 0 10.34 0 13V16H16V13C16 10.34 10.67 9 8 9Z" fill="#886BE3" />
                </svg>
                {{ role }}
            </span>
        </span>
      <span v-if="!item.roles.length" class="role-table">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 1.9C9.16 1.9 10.1 2.84 10.1 4C10.1 5.16 9.16 6.1 8 6.1C6.84 6.1 5.9 5.16 5.9 4C5.9 2.84 6.84 1.9 8 1.9ZM8 10.9C10.97 10.9 14.1 12.36 14.1 13V14.1H1.9V13C1.9 12.36 5.03 10.9 8 10.9ZM8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 9C5.33 9 0 10.34 0 13V16H16V13C16 10.34 10.67 9 8 9Z" fill="#886BE3" />
                </svg>
                {{ $t('buyer') }}
      </span>
    </template>
    <template #[`item.name`]="{item}">
        <router-link style="text-decoration: none" :to="{name: 'user', params: {id: item.id}}">
            <v-avatar size="24" class="mr-4">
                <v-img :src="item.avatar||defaultAvatar"></v-img>
            </v-avatar>{{ item.name }}
        </router-link>
    </template>
    <template #[`item.status`]="{item}">
        <v-chip small :color="statusColor[status[item.status]]" :class="`${statusColor[status[item.status]]}--text`" class="v-chip-light-bg">
            {{ status[item.status] }}
        </v-chip>
    </template>
    <template #[`item.eye`]="{item}">
      <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              :to="{name: 'user', params: {id: item.id}}"
            >
              <v-icon size="26">
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ 'View'}}</span>
        </v-tooltip>
    </template>
    <template #[`item.store_title`]="{item}">
        <router-link v-if="item.store_title" style="text-decoration: none" :to="{name: 'user', params: {id: item.id, tab: 'store'}}">
            {{ item.store_title }}
        </router-link>
        <rating v-if="item.store_rating" :id-rating="item.store_rating" />
        <span v-if="!item.store_title">
            {{ $t('Not created') }}
        </span>
    </template>
</v-data-table>
</template>

<script>
import {mdiEyeOutline} from '@mdi/js'
import { useVModel } from '@vueuse/core';
import { ref, watch, computed } from "@vue/composition-api";
import store from '@/store';
import { useRouter } from "@core/utils";
import { getUserHeadersByTabName } from "@/plugins/helpers"
import Rating from "@/views/market/components/Rating";

export default {
    name: 'UsersTable',
    components: {
        Rating
    },
    setup(props, {
        emit
    }) {
        const { router, route } = useRouter()
        const statusColor = {
            /* eslint-disable key-spacing */
            Active: 'success',
            Banned: 'error',
            /* eslint-enable key-spacing */
        }
        const handleRowClick = (e) => {
            router.push({
                name: 'user',
                params: {
                    id: e.id
                }
            })
        }

        const options = computed(({
            get() {
                return store.state.pagination.users[route.value.params.tab]
            },
            set(val) {
                let ob = {}
                let page = val.page > val.lastPage ? 1 : val.page
                ob[route.value.params.tab] = Object.assign({}, val, {
                    roleTab: route.value.params.tab,
                    search: val.search ? val.search : route.value.query.search,
                    page
                })
                store.commit("pagination/SET_USERS_PAGINATION", ob);
            }
        }))

        const getUsersFromApi = () => {
            store.dispatch('user/getUsers', options.value)
        }

        const globalItemsPerPage = computed(() => store.getters['nav/globalItemPerPage'])

        const updateItemsPerPage = (e) => {
            store.commit("nav/SET_GLOBAL_ITEMS_PER_PAGE", e);
            getUsersFromApi()
        }

        getUsersFromApi()

        if(store.state.pagination.users[route.value.params.tab].search) {
            router.push({ name: route.value.name, query: { search: store.state.pagination.users[route.value.params.tab].search }})
        }

        return {
            updateItemsPerPage,
            globalItemsPerPage,
            getUsersFromApi,
            handleRowClick,
            statusColor,
            status: {
                1: 'Active',
                0: 'Banned'
            },
            search: computed(() => store.state.user.search),
            options,
            users: computed(() => store.state.user.users),
            total: computed(() => store.state.user.total),
            loading: computed(() => store.state.user.loading),
            getUserHeadersByTabName,
            defaultAvatar: require('@/assets/images/avatars/1.png'),
            icons: {
                mdiEyeOutline
            }
        }
    },
}
</script>

<template>
<div class="search">
    <v-text-field v-model.lazy="search" :label="$t('Search ...')" append-icon="mdi-magnify" class="mx-4" @input="getUsers($event)"></v-text-field>
</div>
</template>

<script>
import store from '@/store'
import { useVModel } from '@vueuse/core'
import { useRouter } from '@core/utils'
import { ref, computed, watch } from "@vue/composition-api"

export default {
    name: 'UsersSearch',
    setup(props, {
        emit
    }) {
        const timer = ref(null)
        const { router, route } = useRouter()
        const search = computed(({
            get() {
                return store.state.pagination.users[route.value.params.tab].search;
            },
            set(val) {
                router.replace({ name: 'users', query: { search: val } })
                let ob = {}
                ob[route.value.params.tab] = {
                    search: val
                }
                store.commit("pagination/SET_USERS_PAGINATION", ob);
            }
        }))
        const getUsers = function (e) {
            if (timer.value) {
                clearTimeout(timer.value)
                timer.value = null
            }
            timer.value = setTimeout(() => {
                store.dispatch('user/getUsers', Object.assign({}, store.state.pagination.users[route.value.params.tab], {
                    search: e,
                    page: 1
                }))
            }, 1000)
        }
        return {
            getUsers,
            search
        }
    }
}
</script>

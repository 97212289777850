<template>
<div class="saa-users-container">
    <account-role-title role="Admin" :v-if="loading"/>
    <!-- <breadcrumbs /> -->
    <UsersInfo />
    <v-row>
      <v-col><span class="title">{{ $t('Users') }}</span></v-col>
    </v-row>
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="(tab, index) in tabs" :key="index" @click="$router.push({name:'users', params:{tab:tab.route}, query:{t:new Date().getTime()}})">
            <span>{{ $t(tab.title) }}</span>
        </v-tab>
        <!-- tabs item -->
        <v-tabs-items v-model="tab">
            <v-tab-item v-for="(tab, index) in tabs" :key="index">
                <div class="d-flex justify-end">
                    <users-search />
                </div>
                <!-- tab component -->
                <component v-if="tab.route === $route.params.tab" :is="tab.component" />
            </v-tab-item>
        </v-tabs-items>
    </v-tabs>
</div>
</template>

<script>
import User from "@/views/cabinet/user/User";
import UsersInfo from "@/views/cabinet/user/UsersInfo";
import UsersTable from "@/views/cabinet/user/UsersTable";
import UsersSearch from "@/views/cabinet/user/UsersSearch";
import {
    onBeforeMount,
    ref,
    watch,
    computed
} from "@vue/composition-api";
import {
    useRouter
} from '@core/utils'
import axios from "@axios";
import store from '@/store';

export default {
    name: "Users",
    components: {
        User,
        UsersTable,
        UsersInfo,
        UsersSearch
    },
    setup() {
        const tabs = [
            { title: 'All', route: 'all', component: UsersTable },
            { title: 'Buyers', route: 'buyer', component: UsersTable },
            { title: 'Sellers', route: 'seller', component: UsersTable },
            { title: 'Moderators/Admins', route: 'moderator', component: UsersTable }
        ]
        const { router, route } = useRouter()
        const tab = ref(tabs.findIndex(tab => tab.route === route.value.params.tab))

        watch(() => route.value.params.tab, (newTab) => {
                tab.value = tabs.findIndex(tab => tab.route === newTab)
            }
        )

        return {
            tab,
            tabs,
            loading: computed(() => store.state.user.loading),
        }
    }
}
</script>
